<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ pageTitle }}
    </v-card-title>
    <v-card-text class="d-flex align-items-center">
      <div class="table-v-action-button mr-3" @click="getProjecoes()">
        <v-icon>mdi-chart-timeline-variant-shimmer</v-icon> Projeção
      </div>

      <v-menu offset-y dense>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="table-v-action-button mr-2 exportacao"
            :aria-label="'exportacao'"
          >
            <v-icon>mdi-file-export-outline</v-icon>Exportação
          </div>
        </template>
        <v-list>
          <v-list-item
            style="cursor: pointer"
            @click="handleExportCsv()"
          >
            <v-list-item-title class="v-label-input exportarCsv" :aria-label="'exportarCsv'">
              <v-icon left>mdi-file-delimited-outline</v-icon>Exportar CSV
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            @click="handleExportXlsx()"
          >
            <v-list-item-title class="v-label-input exportarXlsx" :aria-label="'exportarXlsx'">
              <v-icon left>mdi-file-excel-outline</v-icon>Exportar XLSX
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <year-select
        class="table-v-action-button mr-3"
        style="max-width: 150px"
        @change="doLoad($event)"
      ></year-select>
    </v-card-text>
    <div class="table-container">
      <div :class="{ 'extra-width': cumprimentoObrigacao.totalFat }">
        <table-v
          :cols="obrigacaoCols"
          :disablePagination="true"
          :hasExportCSV="false"
          :hasFilter="false"
          :opts="opts"
          :rows="obrigacaoRows"
        ></table-v>
        <table-v
          :cols="divisaoCols"
          :disablePagination="true"
          :hasExportCSV="false"
          :hasFilter="false"
          :opts="opts"
          :rows="divisaoRows"
        ></table-v>
        <table-v
          :cols="obrigacaoCols"
          :disablePagination="true"
          :hasExportCSV="false"
          :hasFilter="false"
          :opts="opts"
          :rows="investimentoRows"
        ></table-v>
      </div>
    </div>
    <form-modal
      :cols="projecaoFields"
      :maxWidth="600"
      :opened.sync="showProjecaoModal"
      :opts="opts"
      title="Editar Projeções"
      :value.sync="projecaoFormValue"
      @save="saveProjecao"
    ></form-modal>
  </v-card>
</template>

<script>
import { isNumber, parseInt } from "lodash";
import { mapGetters } from "vuex";

// Ordem das obrigações é importante
const obrigacoes = [
  {
    key: "fat_bruto",
    name: "Faturamento Bruto",
  },
  {
    key: "fat_liquido",
    name: "Faturamento Líquido",
  },
  {
    key: "obrigacao4",
    name: "Obrigação 4%",
  },
  {
    key: "investimento_adicional",
    name: "P&D Adicional",
  },
  {
    key: "total_obrigacao",
    name: "Obrigação Total",
  },
];

// Ordem das divisões é importante
const divisoes = [
  {
    key: "total_obrigacao",
    name: "Valor total do cumprimento de P&D (obrigação + adicional)",
  },
  {
    key: "fundo_FNDCT",
    name: "1 Compromisso no fundo FNDCT (min. 10%)",
    totalFat: true,
  },
  {
    key: "projetos_externos",
    name: "2 Compromisso em projetos externos (min. 36%)",
    totalFat: true,
  },
  {
    key: "publicas_NNECo",
    name: "2.1.1 Instituições Públicas N/Ne/Co (min. 8%)",
    totalFat: true,
  },
  {
    key: "privadas_NNECo",
    name: "2.1.2 Instituições Privadas N/Ne/Co (min. 8%)",
    totalFat: true,
  },
  {
    key: "convenios",
    name: "2.2 Compromisso em projetos em convênio na região S/Se (min. 20%)",
    totalFat: true,
  },
  {
    key: "projetos_proprios",
    name: "3 Projetos Próprios",
  },
  {
    key: "ppi",
    name: "Pagamentos em PPI",
    totalFat: true,
  },
];

// Ordem dos investimentos é importante
const investimentos = [
  {
    key: "total",
    name: "Investimento em P&D",
  },
  {
    key: "FNDCT",
    name: "FNDCT",
  },
  {
    key: "FACTI_IncisoIII",
    name: "FACTI - Inciso III (FNDCT)",
  },
  {
    key: "FACTI_IncisoIV",
    name: "FACTI - Inciso VI",
  },
  {
    key: "convenio_NNE_publica",
    name: "Convênio N/NE Pública",
  },
  {
    key: "convenio_NNE_privada",
    name: "Convênio N/NE Privada",
  },
  {
    key: "convenio_outras_regioes",
    name: "Convênio Outras Regiões",
  },
  {
    key: "ped_interno",
    name: "P&D Interno",
  },
  {
    key: "boleto_InsuficienciaPPI",
    name: "Boleto de Insuficiencia/PPI",
  },
];

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    "table-v": () => import("@/components/table-v.vue"),
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(["anoBase", "clientId", "selectedClient"]),
    divisaoCols: function () {
      const [legend, ...rest] = this.obrigacaoCols;
      const cols = [
        {
          ...legend,
          name: "Divisão do P&D",
        },
        ...rest,
      ];

      if (this.cumprimentoObrigacao.totalFat) {
        cols.splice(
          6,
          2,
          {
            key: "saldo_inicial",
            name: "Saldo inicial",
            sortable: false,
            type: this.$fieldTypes.MONEY,
            width: "150px",
          },
          {
            key: "saldo_final",
            name: "Saldo final",
            sortable: false,
            type: this.$fieldTypes.MONEY,
            width: "150px",
          }
        );
      }

      return cols;
    },
    obrigacaoCols: function () {
      const cols = [
        {
          key: "legend",
          name: "",
          sortable: false,
          width: "300px",
        },
        {
          key: "tri1",
          name: "1 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(1),
          width: "150px",
        },
        {
          key: "tri2",
          name: "2 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(2),
          width: "150px",
        },
        {
          key: "tri3",
          name: "3 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(3),
          width: "150px",
        },
        {
          key: "tri4",
          name: "4 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(4),
          width: "150px",
        },
        {
          key: "total",
          name: "TOTAL",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          width: "150px",
        },
      ];

      if (this.cumprimentoObrigacao.totalFat) {
        cols.push(
          {
            key: "saldo_inicial",
            name: "",
            sortable: false,
            width: "150px",
          },
          {
            key: "saldo_final",
            name: "",
            sortable: false,
            width: "150px",
          }
        );
      }

      return cols;
    },
    divisaoRows: function () {
      if (Array.isArray(this.cumprimentoObrigacao?.obrigacao)) {
        const parsed = this.cumprimentoObrigacao.totalFat
          ? divisoes
          : divisoes.filter(({ totalFat }) => !totalFat);
        const divisao = parsed.map(({ key, name }) =>
          this.cumprimentoObrigacao.divisao_ped.reduce(
            (prev, curr) => {
              const tableKey = isNumber(curr.trimestre)
                ? `tri${curr.trimestre}`
                : curr.trimestre;
              return {
                ...prev,
                [tableKey]: curr[key],
              };
            },
            {
              legend: name,
              tri1: 0,
              tri2: 0,
              tri3: 0,
              tri4: 0,
              saldo_inicial: 0,
              saldo_final: 0,
              total: 0,
            }
          )
        );
        const saldo = this.cumprimentoObrigacao.saldo_ped.reduce(
          (prev, { trimestre, valor }) => {
            const tableKey = isNumber(trimestre)
              ? `tri${trimestre}`
              : trimestre;
            return {
              ...prev,
              [tableKey]: valor,
            };
          },
          {
            legend: "Saldo de P&D",
            tri1: 0,
            tri2: 0,
            tri3: 0,
            tri4: 0,
            total: 0,
          }
        );
        const blankLine = {};

        return [...divisao, blankLine, saldo, blankLine];
      }

      return [];
    },
    investimentoRows: function () {
      if (this.cumprimentoObrigacao?.relatorioRDA === "completo") {
        investimentos[7].name = "P&D Interno Completo";
      } else {
        investimentos[7].name = "P&D Interno Simplificado";
      }

      if (Array.isArray(this.cumprimentoObrigacao?.investimento_ped)) {
        const investimentoPaserd = investimentos.map(({ key, name }) =>
          this.cumprimentoObrigacao.investimento_ped.reduce(
            (prev, curr) => {
              const tableKey = isNumber(curr.trimestre)
                ? `tri${curr.trimestre}`
                : curr.trimestre;
              return {
                ...prev,
                [tableKey]: curr[key],
              };
            },
            {
              legend: name,
              tri1: 0,
              tri2: 0,
              tri3: 0,
              tri4: 0,
              total: 0,
            }
          )
        );

        const anoBase = parseInt(this.anoBase, 10);
        const investimentoDiff = investimentos
          .map(({ key, name }) =>{
            const keyDiff = `${key}_dif`;
            return this.cumprimentoObrigacao.investimento_ped.reduce(
              (prev, curr) => ({
                ...prev,
                [`tri${curr.trimestre}`]: curr[keyDiff],
                total: prev.total + curr[keyDiff],
              }),
              {
                legend: `${name} ref ${anoBase} pago ${anoBase + 1}`,
                tri1: 0,
                tri2: 0,
                tri3: 0,
                tri4: 0,
                total: 0,
              }
            )
          })
          .filter(({ total }) => total > 0);
        return investimentoPaserd.concat(investimentoDiff);
      }

      return [];
    },
    obrigacaoRows: function () {
      if (Array.isArray(this.cumprimentoObrigacao?.obrigacao)) {
        return obrigacoes.map(({ key, name }) =>
          this.cumprimentoObrigacao.obrigacao.reduce(
            (prev, curr) => {
              const tableKey = isNumber(curr.trimestre)
                ? `tri${curr.trimestre}`
                : curr.trimestre;
              return {
                ...prev,
                [tableKey]: curr[key],
              };
            },
            {
              legend: name,
              tri1: 0,
              tri2: 0,
              tri3: 0,
              tri4: 0,
              total: 0,
            }
          )
        );
      }

      return [];
    },
    obrigacoesRealizadas: function () {
      const base = {
        tri1: false,
        tri2: false,
        tri3: false,
        tri4: false,
      };

      if (Array.isArray(this.cumprimentoObrigacao?.obrigacao)) {
        return this.cumprimentoObrigacao.obrigacao.reduce(
          (prev, { trimestre, realizado }) => ({
            ...prev,
            [`tri${trimestre}`]: realizado,
          }),
          base
        );
      }

      return base;
    },
    projecaoFields: function () {
      if (!this.anoBase) {
        return [];
      }

      return Array(12)
        .fill(`${this.anoBase}-XX`)
        .reduce((acc, comp, index) => {
          const competencia = comp.replace("XX", `${index + 1}`.padStart(2, 0));
          const month = this.$options.filters.toFullMonth(competencia);

          return [
            ...acc,
            {
              key: competencia,
              name: `Valor de ${month}`,
              type: this.$fieldTypes.MONEY,
              colSize: 4,
            },
          ];
        }, []);
    },
    resource: function () {
      return this.apiResource(
        `/v1/faturamento/cumprimentoObrigacao/${this.clientId}`
      );
    },
    resourceProjecao: function () {
      return this.apiResource(`/v1/faturamento/projecao/${this.clientId}`);
    },
    rowsToExport() {
      return [
        [this.obrigacaoCols, this.obrigacaoRows],
        [this.divisaoCols, this.divisaoRows],
        [this.obrigacaoCols, this.investimentoRows],
      ].reduce((acc, [cols, rows]) => {
        const header = cols.map(({ name }) => name);
        const table = rows.map((row) => cols.map(({ key }) => key in row ? row[key] : ''));
        return [...acc, header, ...table];
      }, []);
    },
    pageTitle: function () {
      return this.$route.meta.pageTitle;
    },
  },
  data: function () {
    return {
      cumprimentoObrigacao: {},
      showProjecaoModal: false,
      projecaoFormValue: {},
      projecoes: [],
      opts: {},
    };
  },
  methods: {
    doLoad: function (anoBase = this.anoBase) {
      if (!this.clientId) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "error",
          title: "Sem empresa selecionada.",
          text: "Selecione uma empresa no cabeçalho antes de acessar esse relatório.",
        });
        this.$router.push("/index");
        return;
      }

      const query = `anoBase=${anoBase}`;
      return this.resource.get({ query }).then((response) => {
        if (response.error) {
          this.cumprimentoObrigacao = {};
        } else {
          this.cumprimentoObrigacao = response;
        }

        return response;
      });
    },
    getProjecoes: function () {
      const query = this.anoBase ? `anoBase=${this.anoBase}` : "";
      this.resourceProjecao.get({ query }).then((response) => {
        if (response.error) {
          console.error(response.error);
          return;
        }

        if (Array.isArray(response)) {
          this.projecaoFormValue = response.reduce(
            (acc, { competencia, valor }) => ({
              ...acc,
              [competencia]: valor,
            }),
            {}
          );
          this.showProjecaoModal = true;
          this.projecoes = response;
        }
      });
    },
    handleExportCsv() {
      this.exportCsv(this.rowsToExport, {
        Título: "Cumprimento de Obrigação de P&D",
        Empresa: this.selectedClient.fantasia,
        Ano: this.anoBase,
      });
    },
    handleExportXlsx() {
      this.exportXlsx2(this.rowsToExport, {
        anoBase: this.anoBase,
        styles: [1, this.obrigacaoRows.length + 2, this.obrigacaoRows.length + this.divisaoRows.length + 3].reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true
              }
            }
          }),
          {}
        )
      });
    },
    parseIcon: function (trimestre) {
      if (!trimestre) {
        return null;
      }

      const realizado = this.obrigacoesRealizadas[`tri${trimestre}`];
      return {
        text: realizado
          ? "mdi-chart-timeline-variant"
          : "mdi-chart-timeline-variant-shimmer",
        style: `margin-left: .5rem; color: ${
          realizado ? "#007bff" : "#6c757d7a"
        }`,
        title: realizado ? "Realizado" : "Projetado",
      };
    },
    saveProjecao: function (formValue, closeFn) {
      const body = Object.entries(formValue).map(([competencia, valor]) => {
        const id =
          this.projecoes.find(
            (projecao) => projecao.competencia === competencia
          )?.id || null;
        return {
          id,
          competencia,
          valor,
        };
      });
      this.resourceProjecao.save(body).then((response) => {
        if (!response.error) {
          if (closeFn) {
            closeFn();
          }

          this.$router.go(0);
        }
      });
    },
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table {
    border-radius: 0px !important;
  }

  .v-data-table__wrapper {
    height: unset !important;
  }
}

.teros-elevation.v-card.v-sheet.theme--light {
  margin-bottom: 1rem;
}

.table-container {
  overflow-x: auto;
}

.extra-width {
  min-width: 1350px;
}
</style>
